import React, { useState, useEffect } from 'react'
import '../projectsGallery/projectGallery.css'


import ProjectCard from '../cards/projectCard'
import seg_bg from "../../assets/wallpaper.jpg"
import obj_det_bg from "../../assets/wallpaper2.jpg"
import {Link} from "react-router-dom";
import {getDatasetsAPI} from "../../apis"
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

export const DatasetGallery = (props) => {

  const dataset_img_map = {
    "segmentation": seg_bg,
    "object_detection": obj_det_bg,
    "default": seg_bg
  }
  
  // retrieve info passed via Link.state
  const customer_id = props.location.state?.customer_id;
  const project_id = props.location.state?.project.id;

  const [datasets, setDatasets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      fetchDatasets();
  }, []);
  
  const updateDatasetInfo = (dataset) => {
    const dataset_info = {
      "dataset": dataset,
      "customer_id": customer_id,
      "project_id": project_id
    }
    return dataset_info
    };

  const fetchDatasets = async () => {
    try {
      const url = getDatasetsAPI(customer_id, project_id)

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setDatasets(response.data.datasets)
      setIsLoading(false)
    }
    catch (e) {
      console.log(e.message);
    }
  };
  
  return (
    <div className="ProjectCards">
      {
      (isLoading )  ?
        <>
          <div className="custom-clip-loader">
            <ClipLoader  />
            <p>Please wait</p>
          </div> 
        </>
      :  
        <>
          <h2>Datasets</h2>
          <div className="ProjectCardGroup">
          {datasets.map((dataset) => (
            
            // state holds the data that needs to be passed
            // project.customer_id = {customer_id}

            <Link
            to={{pathname: `/project/${project_id}/dataset/gallery`, state: updateDatasetInfo(dataset)}}
            style={{textDecoration: 'none'}} 
            >
                <ProjectCard
                  key={dataset.key}
                  id={dataset.id}
                  title={dataset.name}
                  text={"N/A"}
                  image={dataset_img_map[dataset.type] || dataset_img_map["default"]}
                />
            </Link>
            ))}
          </div>
        </>
      }
      
    </div>
  ); 
};
export default DatasetGallery