import React, { useEffect, useState } from "react";
import { InferenceSession, Tensor } from 'onnxruntime-web';
import osm from "./osm-providers";
import {MapContainer, TileLayer, useMapEvents, FeatureGroup, Polygon, useMap} from "react-leaflet";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "../geo/styles.css";
import { SAMGeo } from "../geo/sam-geo";
import GeoRaster from "./CogRaster";
import * as L from "leaflet"
import "leaflet-draw/dist/leaflet.draw.css"
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { EditControl } from 'react-leaflet-draw';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';

import decoder_model from "../../../assets/models/sam_decoder.onnx"
import _ from 'lodash';
import {saveLabelUrl} from "../../../apis"

import { getEmbeddingAPI, getLabelsFromImageID } from "../../../apis"

function AddMarkerToClick(props) {

  const {imgUrl, geoLayer} = props
  const [mapBounds, setMapBounds] = useState({});
  const [imageEmbedding, setImageEmbeddings] = useState([]);
  const [imageHeight, setImageHeight] = useState([]);
  const [imageWidth, setImageWidth] = useState([]);
  const [decoderModel, setModel] = useState();
  const [currentState, setCurrentState] = useState('');
  const currentStateRef = useRef(currentState);

  const [allPolygons, setAllPolygons] = useState([]);

  const [editInProgress, setEditInProgress] = useState(false);

  const samGeoObj = new SAMGeo({});

  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const selectedPolygonRef = useRef(selectedPolygon);

  // Function to handle polygon selection
  const handlePolygonSelection = (e) => {
    setSelectedPolygon(e.target);
    selectedPolygonRef.current = e.target
  };

  
  useEffect(() => { 
    const fetchData = async () => {
      await fetchModel();
      const savedPolygons = await fetchPreSavedPolygons();
    }
    fetchData();
  }, [])
  

  async function predict(clickPointX, clickPointY, img_height, img_width, imgEmbeddingTensorArg) {
    
    try {
      if (
        decoderModel === null
      ) {
        console.log("Model not fetched")
      }
        // Preapre the model input in the correct format for SAM.
        // The modelData function is from onnxModelAPI.tsx.
        // Create the tensor
        let n = 1

        let pointCoords = new Float32Array(2 * (n + 1));
        let pointLabels = new Float32Array(n + 1);

        // Add clicks and scale to what SAM expects
        for (let i = 0; i < n; i++) {
          pointCoords[2 * i] = clickPointX;
          pointCoords[2 * i + 1] = clickPointY;
          pointLabels[i] = 1;
        }

        // Add in the extra point/label when only clicks and no box
        // The extra point is at (0, 0) with label -1
        pointCoords[2 * n] = 0.0;
        pointCoords[2 * n + 1] = 0.0;

        const pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + 1, 2]);
        const pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + 1]);
        const imageSizeTensor = new Tensor('float32', [
          img_width,
          img_width,
        ]);
        // There is no previous mask, so default to an empty tensor
        const maskInput = new Tensor(
          'float32',
          new Float32Array(256 * 256),
          [1, 1, 256, 256],
        );
        // There is no previous mask, so default to 0
        const hasMaskInput = new Tensor('float32', [0]);

        const feeds = {
          image_embeddings: imgEmbeddingTensorArg,
          point_coords: pointCoordsTensor,
          point_labels: pointLabelsTensor,
          orig_im_size: imageSizeTensor,
          mask_input: maskInput,
          has_mask_input: hasMaskInput,
        };
        if (feeds === undefined) return;
        // Run the SAM ONNX model with the feeds returned from modelData()
        let results;
        results = await decoderModel.run(feeds);
        const output = results[decoderModel.outputNames[0]];
        return output;
      
    } catch (e) {
      console.log(e);
      setCurrentState('Unable to predict');
      return;
    }
  }

  async function fetchModel() {
    try{
      setCurrentState('Fetching Model');
      // let modelUrl = 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/file/A*eRf_QauRmqoAAAAAAAAAAAAADmJ7AQ/sam_onnx_example.glb'
      // env.wasm.wasmPaths = 'https://npm.elemecdn.com/onnxruntime-web/dist/';
      await InferenceSession.create(decoder_model, {executionProviders: ['wasm']})
      .then(
        curModel => {
          setModel(curModel);
          setCurrentState('Model received');
        }
      )
      .catch(err => {
        setCurrentState('Error while fetching Decoder Model');
      })
    }
    catch (e) {
      setCurrentState('Unable to fetch model');
      return
    }
  }

  async function fetchPreSavedPolygons() {
    try{
      setCurrentState('Fetching and Applying Labels');
      const GET_LABELS_URL = getLabelsFromImageID(
        props.customer_id,
        props.project_id,
        props.dataset_id,
        props.labelset_id,
        props.image_id)
      await axios.get(GET_LABELS_URL)
      .then(response => {
        setCurrentState('Done');
        const savedPolygons = response.data.label.polygons
        setAllPolygons(allPolygons => [...allPolygons, ...savedPolygons] );
        return savedPolygons
      })
      .catch(err => {
        setCurrentState('Error while fetching labels from Server');
      })
    }
    catch (e) {
      setCurrentState('Unable to fetch labels');
      return
    }
  }

  async function fetchEmbeddings(curMapBounds) {
    try {
      const data = {}
      data["imageURL"] = imgUrl
      data["mapBounds"] = curMapBounds
      let EMBEDDING_URL = getEmbeddingAPI()
      setCurrentState('Fetching Embedding');
      setMapBounds(curMapBounds)
      // const action = EMBEDDING_URL;
      let tensor, img_height, img_width;
      await axios.post(EMBEDDING_URL, data)
      .then(buffer => {
        // let arr_length = res.data.embedding.length;
        // let buffer = new ArrayBuffer( arr_length * 2 );
        // set embedding to model
        let array = buffer.data.embedding
        img_height = buffer.data.height
        img_width = buffer.data.width
        tensor = new Tensor('float32', array.flat(3), [1, 256, 64, 64]);

      })
      .catch(err => {
        setCurrentState('Error while fetching embeddings from Server');
      })
      return [tensor, img_height, img_width];
    } catch (error) {
      setCurrentState('Error while fetching embeddings');
      return
    }
  }

  

  const map = useMapEvents({
     async click(e) {
      try{
        if (editInProgress){
          return
        }
        
        const clickLatLng = e.latlng
        const clickLat = clickLatLng.lat
        const clickLng = clickLatLng.lng

        var map_bounds = map.getBounds()
        var layer_bounds = geoLayer.getBounds()
        var map_north_east = map_bounds._northEast
        var map_south_west = map_bounds._southWest
        var layer_north_east = layer_bounds._northEast
        var layer_south_west = layer_bounds._southWest

        var southwest_lat = Math.max(map_south_west.lat, layer_south_west.lat)
        var southwest_lng = Math.max(map_south_west.lng, layer_south_west.lng)
        var northeast_lat = Math.min(map_north_east.lat, layer_north_east.lat)
        var northeast_lng = Math.min(map_north_east.lng, layer_north_east.lng)


        let curMapBounds = {
          "north_east": 
            {"lat": northeast_lat, "lng": northeast_lng},
          "south_west": 
            {"lat": southwest_lat, "lng": southwest_lng}
          }
  

        var extent = [northeast_lng, northeast_lat, southwest_lng, southwest_lat];
  
        let img_embedding_tensor, img_height, img_width;
        
        if (!(_.isEqual(mapBounds, curMapBounds))){
          setCurrentState('Fetching embedding');
          currentStateRef.current = 'Fetching embedding'
  
          const values = await fetchEmbeddings(curMapBounds);
          img_embedding_tensor = values[0]
          img_height = values[1]
          img_width = values[2]
          
          setImageEmbeddings(img_embedding_tensor);
          setImageHeight(img_height)
          setImageWidth(img_width)
          setCurrentState('Embedding received');
          currentStateRef.current = 'Fetching embedding'
        }
        else {
          img_embedding_tensor = imageEmbedding;
          img_height = imageHeight
          img_width = imageWidth
        }
        // point 1
        let clickPointX, clickPointY;
        clickPointX = Math.round((clickLng - southwest_lng)/(northeast_lng - southwest_lng)*img_width)
        clickPointY = Math.round((clickLat - northeast_lat)/(southwest_lat - northeast_lat)*img_height)
        let output;
        output = await predict(clickPointX, clickPointY, img_height, img_width, img_embedding_tensor);
        samGeoObj.setGeoImage(
          extent,
          img_height,
          img_width,
        );
        let vector_points = await samGeoObj.exportGeoPolygon(output)
        let polygon_points = []
        if (vector_points.geometry.type == "MultiPolygon") {
          polygon_points = samGeoObj.flattenMultiPolygonList(vector_points)
        }

        let polygon_point_coords = samGeoObj.getPolygonCoordsFromVectorPoints(
          polygon_points, img_width, img_height, northeast_lat, northeast_lng, southwest_lat, southwest_lng)
        setAllPolygons(allPolygons => [...allPolygons, ...polygon_point_coords] );
        
        setCurrentState('Done')
        currentStateRef.current = 'Done'
      } catch(error){
          console.error('An error occurred:', error);
      }
    },
    
  })

  const mapRef = useMap();

  // Function to handle edit start
  const handleEditStart = () => {
    setEditInProgress(true);
  };

  // Function to handle edit start
  const handleDeleteStart = () => {
    setEditInProgress(true);
  };

  // Function to handle delete end
  const handleDeleted = (e) => {
    setEditInProgress(false);
  };

  // Function to handle edit end
  const handleEdited = (e) => {
    setEditInProgress(false);
  };

  const handleCreated = (e) => {
  };

  const handleDrawStart = (e) => {
    handleEditStart()
  };

  const handleDrawStop = (e) => {
    handleEdited()
  };

  map.on('draw:drawstart', handleDrawStart);
  map.on('draw:drawstop', handleDrawStop);

  return (
    <>
    <FeatureGroup>
      {/* Add EditControl for polygon editing */}
      <EditControl
          position='topright'
          draw={{
            rectangle: true,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: true,
          }}
          edit={{
            edit: true,
            remove: true,
            featureGroup: {
              type: 'FeatureCollection',
              features: allPolygons.map((coordinates, index) => ({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [coordinates]
                }
              }))
            }
          }}
          onEditStart={handleEditStart} // Handle edit start
          onDeleteStart={handleDeleteStart} // Handle edit start
          onCreated={handleCreated}
          onEdited={handleEdited}
          onDeleted={handleDeleted}
      />
      <>
        {/* Render the polygon using the coordinates from state */}
        {allPolygons.map(
          (coordinates, index) => (
            <Polygon
              key={index} 
              positions={coordinates} 
              eventHandlers={{
                click: handlePolygonSelection, // Assign click event handler to handlePolygonSelection
              }}
            />
          )
        )}
      </>

      {
      (
        currentState !== 'Model received' && 
        currentState !== 'Done' && 
        currentState !== 'Embedding received' )  ?
        (<>
        <div className="custom-clip-loader">
          <ClipLoader  />
          <p>{currentState}</p>
        </div> 
      </>) : <></>
      }
      
      
    </FeatureGroup>
    </>
  )
}

const Maps = (props) => {
  const ZOOM_LEVEL = 9;
  const mapRef = useRef();
  const [geoLayer, setGeoLayer] = useState();
  const [currentParentState, setCurrentParentState] = useState();
  const [toastMessage, setToastMessage] = useState('');

  const setRasterLayer = (layer) => {
    setGeoLayer(layer)
  };
  
  useEffect(() => {
    // Clear the state value after 3 seconds (3000 milliseconds)
    const timer = setTimeout(() => {
      setToastMessage('');
    }, 1000);

    // Clear the timer if the component unmounts to avoid memory leaks
    return () => clearTimeout(timer);
  }, [toastMessage]);

  const onSaveButtonClick = async(props) => {
    const map = mapRef.current;
    const allpolygons = [];
    const allLabels = {};
    setCurrentParentState('Saving')
    
    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        const latLngs = layer.getLatLngs();
        const latLngsAsIntegers = latLngs.flat().map((latLng) => ({
          lat: latLng.lat,
          lng: latLng.lng,
        }));
        allpolygons.push(latLngsAsIntegers);
      }
    });
    allLabels["polygons"] = allpolygons
    console.log('All Labels:', allLabels);
    try {
      const url = saveLabelUrl(
        props.customer_id,
        props.project_id,
        props.dataset_id,
        props.labelset_id
      )
      const data = {}
      data["labels"] = allLabels
      data["image_name"] = props.thumbnail_id
      const response = await axios.post(
        url, 
        data,
        {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setCurrentParentState('')
      setToastMessage('Saved');
    }
    catch(e) {
      setCurrentParentState('Unable to save labels');
    }
  }
  
  const renderToolBox = () => {
    return (
      <div className='toolbox-right'>
          <Tooltip title="Save">
            <SaveIcon 
            onClick={() => onSaveButtonClick(props)} 
            className='toolbox-icon' fontSize="large"/>
          </Tooltip> 
      </div>
    )
  }

  return (
    <>
      {/* <Alert style={{"margin-left": "250px"}} severity="warning" className="">
        Fetching embedding takes about a minute. We are transitioning to GPU servers soon.
      </Alert> */}
      <MapContainer zoom={ZOOM_LEVEL} ref={mapRef}>
          <TileLayer
              url={osm.maptiler.url}
              attribution={osm.maptiler.attribution}
          />
          <GeoRaster 
              url={props.img_url}
              sendRasterLayer={setRasterLayer}
              >
          </GeoRaster>
          <AddMarkerToClick 
            imgUrl={props.img_url} 
            geoLayer={geoLayer}
            customer_id={props.customer_id}
            project_id={props.project_id}
            dataset_id={props.dataset_id}
            labelset_id={props.labelset_id}
            image_id={props.thumbnail_id}
            />
            {
              (
                currentParentState == 'Saving')  ?
                (
                  <>
                    <div className="custom-clip-loader">
                      <ClipLoader  />
                      <p>{currentParentState}</p>
                    </div> 
                  </>
                ) 
                : 
                  <></>
            }
            {
              (
                toastMessage != '')  ?
                (
                  <>
                    <div className="custom-clip-loader">
                      <p>{toastMessage}</p>
                    </div> 
                  </>
                ) 
                : 
                  <></>
            }
      </MapContainer>
      {renderToolBox()}
    </>
  );
}

export default Maps;
