import React from "react";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import Projects from "./views/Projects"
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import {Helmet} from 'react-helmet';
import ImageGallery from "./components/imageGallery/imageGallery"
import DatasetGallery from "./components/datasetGallery/datasetGallery"
import DatasetPage from "./components/datasetPage/datasetPage"
import LabelSetGallery from "./components/labelSetGallery/labelSetGallery"
// styles
import "./App.css";
import * as ort from 'onnxruntime-web';

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

ort.env.debug = false;
// set global logging level
ort.env.logLevel = 'verbose';

// override path of wasm files - for each file
ort.env.wasm.numThreads = 2;
ort.env.wasm.simd = true;
// ort.env.wasm.proxy = true;
ort.env.wasm.wasmPaths = {
  'ort-wasm.wasm': '/ort-wasm.wasm',
  'ort-wasm-simd.wasm': '/ort-wasm-simd.wasm',
  'ort-wasm-threaded.wasm': '/ort-wasm-threaded.wasm',
  'ort-wasm-simd-threaded.wasm': '/ort-wasm-simd-threaded.wasm'
};

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <Header />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/external-api" component={ExternalApi} />
              <Route path="/projects" component={Projects} />
              <Route path="/project/:id/datasets" component={DatasetGallery} />
              <Route path="/project/:id/dataset/gallery" component={DatasetPage} />
              <Route path="/project/:id/dataset/:id/images" component={ImageGallery} />
              <Route path="/project/:id/dataset/:id/labelsets" component={LabelSetGallery} />
            </Switch>
          </Container>
          <Footer></Footer>
        </div>
      </Router>
    </>
    
  );
};

export default App;
