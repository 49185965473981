import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { SAMGeo } from "../geo/sam-geo";
import { InferenceSession, Tensor } from 'onnxruntime-web';
import axios from "axios";
import { getEmbeddingAPI, getLabelsFromImageID } from "../../../apis"
import decoder_model from "../../../assets/models/sam_decoder.onnx"
import ClipLoader from "react-spinners/ClipLoader";

const ImageMap = (props) => {
  const mapRef = useRef(null);
  const drawnItems = useRef(new L.FeatureGroup());

  const [imageEmbedding, setImageEmbeddings] = useState(null);
  const [imageHeight, setImageHeight] = useState([]);
  const [imageWidth, setImageWidth] = useState([]);
  const imageEmbeddingRef = useRef(imageEmbedding);
  const imageHeightRef = useRef(imageHeight);
  const imageWidthRef = useRef(imageWidth);

  const [decoderModel, setModel] = useState();
  const decoderModelRef = useRef(decoderModel);

  const [currentState, setCurrentState] = useState('');
  const currentStateRef = useRef(currentState);

  const [allPolygons, setAllPolygons] = useState([]);
  const allPolygonsRef = useRef(allPolygons);

  const [editInProgress, setEditInProgress] = useState(false);
  const editInProgressRef = useRef(editInProgress)

  const samGeoObj = new SAMGeo({});

  async function predict(clickPointX, clickPointY, img_height, img_width, imgEmbeddingTensorArg) {
    
    try {
      if (
        decoderModelRef.current === null
      ) {
        console.log("Model not fetched")
      }
        // Preapre the model input in the correct format for SAM.
        // The modelData function is from onnxModelAPI.tsx.
        // Create the tensor
        let n = 1

        let pointCoords = new Float32Array(2 * (n + 1));
        let pointLabels = new Float32Array(n + 1);

        // Add clicks and scale to what SAM expects
        for (let i = 0; i < n; i++) {
          pointCoords[2 * i] = clickPointX;
          pointCoords[2 * i + 1] = clickPointY;
          pointLabels[i] = 1;
        }

        // Add in the extra point/label when only clicks and no box
        // The extra point is at (0, 0) with label -1
        pointCoords[2 * n] = 0.0;
        pointCoords[2 * n + 1] = 0.0;

        const pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + 1, 2]);
        const pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + 1]);
        const imageSizeTensor = new Tensor('float32', [
          img_width,
          img_width,
        ]);
        // There is no previous mask, so default to an empty tensor
        const maskInput = new Tensor(
          'float32',
          new Float32Array(256 * 256),
          [1, 1, 256, 256],
        );
        // There is no previous mask, so default to 0
        const hasMaskInput = new Tensor('float32', [0]);

        const feeds = {
          image_embeddings: imgEmbeddingTensorArg,
          point_coords: pointCoordsTensor,
          point_labels: pointLabelsTensor,
          orig_im_size: imageSizeTensor,
          mask_input: maskInput,
          has_mask_input: hasMaskInput,
        };
        if (feeds === undefined) return;
        // Run the SAM ONNX model with the feeds returned from modelData()
        let results;
        results = await decoderModelRef.current.run(feeds);
        const output = results[decoderModelRef.current.outputNames[0]];
        return output;
      
    } catch (e) {
      console.log(e);
      setCurrentState('Unable to predict');
      return;
    }
  }

  async function fetchModel() {
    try{
      setCurrentState('Fetching Model');
      // let modelUrl = 'https://mdn.alipayobjects.com/huamei_qa8qxu/afts/file/A*eRf_QauRmqoAAAAAAAAAAAAADmJ7AQ/sam_onnx_example.glb'
      // env.wasm.wasmPaths = 'https://npm.elemecdn.com/onnxruntime-web/dist/';
      await InferenceSession.create(decoder_model, {executionProviders: ['wasm']})
      .then(
        curModel => {
          setModel(curModel);
          decoderModelRef.current = curModel
          setCurrentState('Model received');
          return curModel
        }
      )
      .catch(err => {
        setCurrentState('Error while fetching Decoder Model');
      })
    }
    catch (e) {
      setCurrentState('Unable to fetch model');
      return
    }
  }

  async function fetchPreSavedPolygons() {
    try{
      setCurrentState('Fetching and Applying Labels');
      const GET_LABELS_URL = getLabelsFromImageID(
        props.customer_id,
        props.project_id,
        props.dataset_id,
        props.labelset_id,
        props.thumbnail_id)
      await axios.get(GET_LABELS_URL)
      .then(response => {
        setCurrentState('Done');
        const savedPolygons = response.data.label?.polygons
        if(savedPolygons){
          setAllPolygons(allPolygons => [...allPolygons, ...savedPolygons] );
        }
        return savedPolygons
      })
      .catch(err => {
        setCurrentState('Error while fetching labels from Server');
      })
    }
    catch (e) {
      setCurrentState('Unable to fetch labels');
      return
    }
  }

  async function fetchEmbeddings() {
    try {
      const data = {}
      data["imageURL"] = props.imageUrl
      let EMBEDDING_URL = getEmbeddingAPI()
      setCurrentState('Fetching Embedding');
      // const action = EMBEDDING_URL;
      let tensor, img_height, img_width;
      await axios.post(EMBEDDING_URL, data)
      .then(buffer => {
        // let arr_length = res.data.embedding.length;
        // let buffer = new ArrayBuffer( arr_length * 2 );
        // set embedding to model
        let array = buffer.data.embedding
        img_height = buffer.data.height
        img_width = buffer.data.width
        tensor = new Tensor('float32', array.flat(3), [1, 256, 64, 64]);

      })
      .catch(err => {
        setCurrentState('Error while fetching embeddings from Server');
      })
      return [tensor, img_height, img_width];
    } catch (error) {
      setCurrentState('Error while fetching embeddings');
      return
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchModel();
      const savedPolygons = await fetchPreSavedPolygons();
    }

    fetchData();

    const imageElement = new Image();
    imageElement.src = props.imageUrl;

    imageElement.onload = () => {
      if (!mapRef.current) {
        const map = L.map('map', {
          crs: L.CRS.Simple,
          minZoom: -1,
          attributionControl: false,
          
        });

        L.imageOverlay(props.imageUrl, [[0, 0], [imageElement.height, imageElement.width]]).addTo(map);
        map.fitBounds([[0, 0], [imageElement.height, imageElement.width]]);
        
        mapRef.current = map;
        map.addLayer(drawnItems.current);

        const drawControl = new L.Control.Draw({
          position: 'topright',
          edit: {
            featureGroup: drawnItems.current
          },
          draw: {
            polygon: true,
            circle: false,
            rectangle: false,
            marker: false,
            polyline: false,
            circlemarker: false,
          },
        });
        map.addControl(drawControl);

        map.on(L.Draw.Event.CREATED, (e) => {
          const { layer } = e;
          drawnItems.current.addLayer(layer);
        });

        map.on('click', async(e) => {
          try{
            if (editInProgressRef.current){
              return
            }
          let img_embedding_tensor, img_height, img_width;  
          if (imageEmbeddingRef.current === null){
            setCurrentState('Fetching embedding');
            currentStateRef.current = 'Fetching embedding'
    
            const values = await fetchEmbeddings();
            img_embedding_tensor = values[0]
            img_height = values[1]
            img_width = values[2]
            
            setImageEmbeddings(img_embedding_tensor);
            setImageHeight(img_height)
            setImageWidth(img_width)
            imageEmbeddingRef.current = img_embedding_tensor
            imageHeightRef.current = img_height
            imageWidthRef.current = img_width

            setImageWidth(img_width)

            setCurrentState('Embedding received');
            currentStateRef.current = 'Embedding received'
          }
          else {
            img_embedding_tensor = imageEmbeddingRef.current;
            img_height = imageHeightRef.current
            img_width = imageWidthRef.current
          }
          const { lat, lng } = e.latlng;
          // Convert lat/lng to the image's simple coordinate system
          const clickPointX = lng;
          const clickPointY = imageElement.height - lat; // Flip the y-axis
          console.log(`Clicked coordinates in image: x ${clickPointX}, y ${clickPointY}`);

          const output = await predict(clickPointX, clickPointY, img_height, img_width, img_embedding_tensor);

          const extent = null;
          samGeoObj.setGeoImage(
            extent,
            img_height,
            img_width,
          );
          let vector_points = await samGeoObj.exportGeoPolygon(output)
          let polygon_points = []
          if (vector_points.geometry.type == "MultiPolygon") {
            polygon_points = samGeoObj.flattenMultiPolygonList(vector_points)
          }
  
          setAllPolygons(allPolygons => [...allPolygons, ...polygon_points] );
          allPolygonsRef.current = [...allPolygonsRef.current, ...polygon_points]

          setCurrentState('Done')
          currentStateRef.current = 'Done'
        } catch(error){
            console.error('An error occurred:', error);
        }

        
        // add predicted Polygons to the map
        allPolygonsRef.current.forEach(coords => {
            const newPolygon = L.polygon(coords).addTo(map);
            drawnItems.current.addLayer(newPolygon);
          });
        });

         // Add event listener for edit start
         map.on('draw:editstart', (e) => {
          setEditInProgress(true)
          editInProgressRef.current = true
        });

        // Add event listener for edit stop
        map.on('draw:editstop', (e) => {
          setEditInProgress(false)
          editInProgressRef.current = false
        });

        // Add event listener for draw start
        map.on('draw:drawstart', (e) => {
          setEditInProgress(true)
          editInProgressRef.current = true
        });

        // Add event listener for draw stop
        map.on('draw:drawstop', (e) => {
          setEditInProgress(false)
          editInProgressRef.current = false
        });

        // Add event listener for delete start
        map.on('draw:deletestart', (e) => {
          setEditInProgress(true)
          editInProgressRef.current = true
        });

        // Add event listener for delete stop
        map.on('draw:deletestop', (e) => {
          setEditInProgress(false)
          editInProgressRef.current = false
        });
      }
    };

    return () => {
      if (mapRef.current) {
        mapRef.current.off();
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [props.imageUrl]);


  return (
    <>
    <div id="map" style={{ height: '95%', width: '100%' }} />
    {
      (
        currentState !== '' && 
        currentState !== 'Model received' && 
        currentState !== 'Done' && 
        currentState !== 'Embedding received' )  ?
        (<>
        <div className="custom-clip-loader">
          <ClipLoader  />
          <p>{currentState}</p>
        </div> 
      </>) : <></>
    }
    </>
    
    
  );
};

export default ImageMap;
