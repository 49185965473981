import React from 'react'
import { useState } from "react";
import './imageCard.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import LabelModal from '../label_tool/LabelModal';



const ImageCard = (props) => {

    const [isModalOpen, setModalOpen] = useState(false);
    

    const openModal = () => {
        setModalOpen(true);
      };
    
    const closeModal = () => {
    setModalOpen(false);
    };

    
    return (
        <>
            <div className="ImageCard" onClick={() => openModal(true)}>
                <img 
                    src={props.thumbnail_url} 
                    alt="No img"/>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
                
            </div>
            {
            (isModalOpen)?
                <LabelModal 
                    isOpen={isModalOpen} 
                    onClose={closeModal}
                    customer_id={props.customer_id}
                    project_id={props.project_id}
                    dataset_id={props.dataset_id}
                    thumbnail_id={props.thumbnail_id}
                    >
                </LabelModal>
            :
                <></>
            }
        </>
    )
}

export default ImageCard