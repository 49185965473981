import React, { useState, useEffect } from 'react'
import './projectGallery.css'


import ProjectCard from '../cards/projectCard'
import seg_bg from "../../assets/wallpaper.jpg"
import obj_det_bg from "../../assets/wallpaper2.jpg"
import {Link} from "react-router-dom";
import {getProjectsAPI} from "../../apis"
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

export const ProjectGallery = (props) => {

  const proj_type_img_map = {
    "segmentation": seg_bg,
    "object_detection": obj_det_bg,
    "default": seg_bg
  }
  
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Get customer id from API
  const customer_id = "customer_id_0"

  useEffect(() => {
      fetchProjects();
  }, []);
  
  const fetchProjects = async () => {
    try {
      const url = getProjectsAPI(customer_id)

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setProjects(response.data.projects)
      setIsLoading(false)
    }
    catch (e) {
      console.log(e.message);
    }
  };
  
  const updateProjInfo = (project) => {
    const proj_info = {
      "project": project,
      "customer_id": customer_id
    }
    return proj_info
    };

  return (
    <div className="ProjectCards">
      {
      (isLoading )  ?
        <>
          <div className="custom-clip-loader">
            <ClipLoader  />
            <p>Please wait</p>
          </div> 
        </>
      :  
        <>
          <h2>Projects</h2>
          <div className="ProjectCardGroup">
          {projects.map((project) => (
            
            // state holds the data that needs to be passed
            // project.customer_id = {customer_id}

            <Link
            to={{pathname: `/project/${project.id}/datasets`, state: updateProjInfo(project)}}
            style={{textDecoration: 'none'}} 
            >
                <ProjectCard
                  key={project.key}
                  id={project.id}
                  title={project.name}
                  text={"N/A"}
                  image={proj_type_img_map[project.type] || proj_type_img_map["default"]}
                />
            </Link>
            ))}
          </div>
        </>
      }
      
    </div>
  ); 
};
export default ProjectGallery