import React, { useState, useEffect, useCallback } from 'react'; // Import useCallback
import '../projectsGallery/projectGallery.css';

import ProjectCard from '../cards/projectCard';
import seg_bg from "../../assets/wallpaper.jpg";
import obj_det_bg from "../../assets/wallpaper2.jpg";
import { Link } from "react-router-dom";
import { getDatasetsAPI } from "../../apis";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

export const DatasetPage = (props) => {

  const dataset_img_map = {
    "segmentation": seg_bg,
    "object_detection": obj_det_bg,
    "default": seg_bg
  };

  // Retrieve info passed via Link.state
  const customer_id = props.location.state?.customer_id;
  const project_id = props.location.state?.project_id;

  console.log(customer_id,project_id)
  const [datasets, setDatasets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Define fetchDatasets using useCallback
  const fetchDatasets = useCallback(async () => {
    try {
      const url = getDatasetsAPI(customer_id, project_id);
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setDatasets(response.data.datasets);
      setIsLoading(false);
      console.log('customer_id','project_id')
    } catch (e) {
      console.log(e.message);
    }
  }, [customer_id,project_id]); 

  useEffect(() => {
    fetchDatasets();
  }, [fetchDatasets]); 

  const updateDatasetInfo = (dataset) => {
    const dataset_info = {
      "dataset": dataset,
      "customer_id": customer_id,
      "project_id": project_id
    };
    return dataset_info;
  };

  return (
    <div className="ProjectCards">
      {
        (isLoading )  ?
        <>
          <div className="custom-clip-loader">
            <ClipLoader  />
            <p>Please wait</p>
          </div> 
        </>
      : 
        <>
          <h2>Images / Labelset </h2>
          <div className="ProjectCardGroup">
          {datasets.map((dataset) => (
                <Link
                  to={{
                    pathname: `/project/${project_id}/dataset/${dataset.id}/images`,state: updateDatasetInfo(dataset)}}
                    style={{ textDecoration: 'none' }}
                  >
                  <ProjectCard
                    key={dataset.key}
                    id={dataset.id}
                    title={'Images'}
                    text={"N/A"}
                    image={dataset_img_map[dataset.type] || dataset_img_map["default"]}
                  />
                </Link>
              ))}
              {datasets.map((dataset) => (
                <Link
                  to={{
                    pathname: `/project/${project_id}/dataset/${dataset.id}/labelsets`,
                    state: updateDatasetInfo(dataset),
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <ProjectCard
                    key={dataset.key}
                    id={dataset.id}
                    title={'Labelsets'}
                    text={"N/A"}
                    image={dataset_img_map[dataset.type] || dataset_img_map["default"]}
                  />
                </Link>
              ))}
          </div>
        </>
      }
    </div>
  );
};

export default DatasetPage;
