import React, { useState , useEffect} from 'react'
import './labelSetGallery.css'

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Loading";
import LabelSetCard from '../cards/labelsetCard'

import seg_bg from "../../assets/wallpaper.jpg"
import obj_det_bg from "../../assets/wallpaper2.jpg"
import ClipLoader from "react-spinners/ClipLoader";
import {getLabelSetsAPI} from "../../apis"
import axios from "axios";


const get_thumbnail_id = (img_key) => {
  if (img_key == null){
    return
  }
  return img_key.split("/").pop()
}

const LabelSetGallery = (props) => {
  // props.location.state contains the data passed via Link  
  const cust_id = props.location.state?.customer_id;
  const proj_id = props.location.state?.project_id;
  const dataset_id = props.location.state?.dataset.id;

  const [labelsets, setLabelsets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const labelset_img_map = {
    "segmentation": seg_bg,
    "object_detection": obj_det_bg,
    "default": seg_bg
  }

  // Initial fetch to populate data
  useEffect(() => {
    fetchLabelSets();
  }, []);

  const fetchLabelSets = async () => {
    try {
      const url = getLabelSetsAPI(cust_id, proj_id, dataset_id)

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setLabelsets(response.data.labelsets)
      setIsLoading(false)
    }
    catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="ProjectCards">
      {
        (isLoading )  ?
        <>
          <div className="custom-clip-loader">
            <ClipLoader  />
            <p>Please wait</p>
          </div> 
        </>
      : 
        <>
          <h2>Labelsets </h2>
          <div className="ProjectCardGroup">
          {labelsets.map((labelset) => (
                  <LabelSetCard
                    key={labelset.key}
                    id={labelset.id}
                    title={labelset.name}
                    text={"N/A"}
                    image={labelset_img_map[labelset.type] || labelset_img_map["default"]}
                    customer_id={cust_id}
                    project_id={proj_id}
                    dataset_id={dataset_id}
                    labelset_id={labelset.id}
                  />
        ))}
          </div>
        </>
      }
    </div>
  );
  
};

export default withAuthenticationRequired(LabelSetGallery, {
  onRedirecting: () => <Loading />,
});
