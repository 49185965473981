export function getEmbeddingAPI() {
    return `${process.env.REACT_APP_SAM_SERVICE_EMBEDDING_API_URL}/predict/get_sam_embedding`
  }

export function getProjectsAPI(customer_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects`
}

export function getDatasetsAPI(customer_id, project_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets`
}

export function getImagesAPI(customer_id, project_id, dataset_id, limit=20, start_after='') {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/dataset/${dataset_id}/list_images?limit=${limit}&start_after=${start_after}`
}

export function getImagePresignedUrl(customer_id, project_id, dataset_id, thumbnail_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets/${dataset_id}/thumbnails/${thumbnail_id}/image_presigned_url`
}

export function saveLabelUrl(customer_id, project_id, dataset_id, labelset_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets/${dataset_id}/labelsets/${labelset_id}/label`
}

export function getLabelsFromImageID(customer_id, project_id, dataset_id, labelset_id, img_name) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets/${dataset_id}/labelsets/${labelset_id}/labels/${img_name}`
}

export function getLabelSetsAPI(customer_id, project_id, dataset_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets/${dataset_id}/labelsets`
}

export function downloadLabelsetAPI(customer_id, project_id, dataset_id, labelset_id) {
  return `${process.env.REACT_APP_PROJECT_SERVICE_URL}/customers/${customer_id}/projects/${project_id}/datasets/${dataset_id}/labelsets/${labelset_id}/download`
}

