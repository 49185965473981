// Modal.js
import React, { useState, useEffect } from 'react';

import { Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {getImagePresignedUrl} from "../../apis"
import axios from "axios";
import Maps from "../label_tool/geo/Maps";
import "./LabelModal.css"
import ImageMap from "./jpeg_png/jpegPng"

const LabelModal = ({ isOpen, onClose,customer_id,project_id,dataset_id,thumbnail_id }) => {
  const [labellingImageUrl, setLabellingImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  

  useEffect(() => {
    fetchImagePresignedUrl();
  }, []);

  const fetchImagePresignedUrl = async () => {
    try {
      const url = getImagePresignedUrl(customer_id, project_id, dataset_id, thumbnail_id)
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setLabellingImageUrl(response.data.presigned_url)
      setIsLoading(false)
    }
    catch (e) {
      setError("Unable to fetch the image")
      setIsLoading(false)
    }
  };

  const getImageExt = (img_presigned_url) => {
    if (img_presigned_url == null){
      return
    }
    let img_name = img_presigned_url.split(/[#?]/)[0].split('/').pop()
    return img_name.split('.').pop().trim();
    };

  const renderLoading = () => { 
    return (
      <div className="custom-clip-loader">
            <ClipLoader  />
            <p>Please wait</p>
      </div>
    );
  }

  const renderError = () => {
    return (
      <>{error}</>
    );
  }

  
  const renderMapandToolbox = () => {
    return (
        <>
          {
              getImageExt(labellingImageUrl) === "tif"  ?
              <>
                <Maps 
                    img_url={labellingImageUrl}
                    customer_id={customer_id}
                    project_id={project_id}
                    dataset_id={dataset_id}
                    labelset_id="labelset_1"
                    thumbnail_id={thumbnail_id}
                >
                </Maps>
              </>
                  :   
              <>
                <ImageMap 
                  className="jpeg_leaflet"
                  imageUrl={labellingImageUrl} 
                  customer_id={customer_id}
                  project_id={project_id}
                  dataset_id={dataset_id}
                  labelset_id="labelset_1"
                  thumbnail_id={thumbnail_id}
                />
              </>
          }
        </>
            
    )
  }

  if (!isOpen) return null;
  return (
    <Modal 
      show={isOpen} 
      onHide={onClose}
      fullscreen={true} 
    >
      <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {
              (isLoading) ? 
                renderLoading()
              :
                (error === "") ?
                  renderMapandToolbox()
                    : 
                  renderError()
          }
      </Modal.Body>
    </Modal>
  );
};

export default LabelModal;
