import React from 'react'
import './labelsetCard.css'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import {downloadLabelsetAPI} from "../../apis"

const LabelSetCard = (props) => {
    const onDownloadButtonClick = async(props) => {
        try {
            const download_url = downloadLabelsetAPI(
                props.customer_id,
                props.project_id,
                props.dataset_id,
                props.labelset_id
            )
            const response = await axios.get(
                download_url,
                {
                responseType: 'blob'
                });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'downloaded-file.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        catch(e) {
        }
      }

    return (
        <>
            <div className="LabelSetCard">
                <img src={props.image} alt="No img"/>
                <h3>{props.title}</h3>
                <div className="DownloadIcon">
                    <Tooltip title="Download Labelset">
                        <CloudDownloadIcon
                            onClick={() => onDownloadButtonClick(props)}>
                        </CloudDownloadIcon>
                    </Tooltip>
                </div>
                <p>{props.text}</p>
            </div>
        </>
    )
}

export default LabelSetCard