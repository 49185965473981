import React, { useState , useEffect} from 'react'
import './imageGallery.css'

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Loading";
import ImageCard from "../cards/imageCard"
import InfiniteScroll from "react-infinite-scroll-component";

import {getImagesAPI} from "../../apis"
import axios from "axios";


const get_thumbnail_id = (img_key) => {
  if (img_key == null){
    return
  }
  let tmp = img_key.split("/")
  let img_name = img_key.split("/").pop()
  let img_name_wo_extension = img_name.split('.')[0]
  return img_name_wo_extension
}

const ImageGallery = (props) => {
  // props.location.state contains the data passed via Link  
  const cust_id = props.location.state?.customer_id;
  const proj_id = props.location.state?.project_id;
  const dataset_id = props.location.state?.dataset.id;

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startAfter, setStartAfter] = useState("");

  // Initial fetch to populate data
  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = async () => {
    try {
      setLoading(true);
      const url = getImagesAPI(
        cust_id,
        proj_id,
        dataset_id, 
        20,
        startAfter
      )
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const newData = response.data
      setItems((prevItems) => [...prevItems, ...newData]);
      if (newData.length === 0) {
        setHasMore(false);
      }
      else{
        setStartAfter(newData[newData.length - 1].key)
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  
  const containerStyle = {
    height: '90vh', // Ensure the container has a defined height
    overflow: 'auto', // Ensure the container is scrollable
  };

  return (
      <InfiniteScroll
        style={containerStyle}
        dataLength={items.length}
        next={fetchMoreData}
        inverse={false}
        hasMore={hasMore}
        loader={loading && <h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div className="ImageCardGroup">
          {items.map((image, index) => (
              <ImageCard
              title={get_thumbnail_id(image.key)}
              customer_id={cust_id}
              project_id={proj_id}
              dataset_id={dataset_id}
              thumbnail_url={image.url}
              thumbnail_id={get_thumbnail_id(image.key)}
            />
          ))}
        </div>
      </InfiniteScroll>
  );
  
};

export default withAuthenticationRequired(ImageGallery, {
  onRedirecting: () => <Loading />,
});

// const url = new URL(image.url);
// const extension = url.pathname.split('.').pop().toLowerCase();
// if (['png', 'jpg', 'jpeg'].includes(extension)) {
//   return <ImageMap imageUrl={image.url} />;
// }